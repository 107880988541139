<template>
    <div class="order-details-page">
    <HeadOrders
    :TitlePage="$route.name"
    :PathPageFrom="$t('Home')"
    :PathPageCenter="$t('requested_packages')"
    :PathPageTo="$t('Order_Detials')" />
    <div class="container">
    <Order_Details />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Packages Order Details',
  components: {
    HeadOrders: defineAsyncComponent( () => import('@/components/Global/HeadOrders.vue') ),
    Order_Details: defineAsyncComponent( () => import('@/components/Packages/Order/Order_Details.vue') ),
  },
  }
  </script>



